import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ArticleCard from "./article-card";

function BlogGridFeat({ articles }) {
  return (
    <section className="flex flex-col items-center justify-center space-y-5 md:flex-row md:space-x-14">
      <div className="h-1/2 w-full max-w-lg space-x-4 space-y-2 rounded-xl drop-shadow-xl">
        <div className="relative rounded-xl object-cover">
          <StaticImage
            src="../assets/images/img-videorecetas.png"
            alt="Video recetas Boones"
            className="h-full w-full rounded-xl object-cover"
          />
          <div className="align-center absolute top-20 left-0 right-0 z-40 text-center md:top-28">
            <Link to="/blog">
              <button className="rounded bg-exoticberry py-2 px-10 uppercase text-white md:w-1/2">
                videorecetas
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-1">
        {articles.slice(0, 2).map((article) => (
          <ArticleCard key={article.slug} article={article} />
        ))}
        <Link to="/blog">
          <button className="mx-auto block rounded bg-deliciousapple py-2 px-10 uppercase text-white md:w-1/2">
            Visitar el blog
          </button>
        </Link>
      </div>
    </section>
  );
}

export default BlogGridFeat;
