import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Coctelescon from "../../components/Sabores/CoctelesconCR";
import BlogGridFeat from "../../components/BlogGridFeatured";
import CarruselCompra from "../../components/CarruselCompra";
import BribeRecetas from "../../components/BribeRecetas";
import CarruselSabores from "../../components/CarruselSabores";
import Seo from "../../components/Seo";

function noventasgiveaway() {
    const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: fecha }) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "90s Giveaway",
          metaDescription:
            "Boone’s te lleva al 90s Pop Tour, participa y gana un pase doble para el concierto.",
        }}
      />
      <header>
        <StaticImage
          src="../../assets/images/banner-boones-90spoptour.jpeg"
          alt="Boones 90s Pop Tour"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="container relative -mt-5 mb-20 md:-mt-20">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div className="max-w-xl space-x-4 space-y-2 rounded-xl bg-white p-5 drop-shadow-xl md:h-56">
              <h1 className="text-center capitalize text-sunpeakpeach text-3xl">
                ¡Boone´s Te Lleva Al 90s Pop Tour!
              </h1>
              <h3 className="text-center text-sunpeakpeach text-xl">
                Participa para ganar:
              </h3>
              <p className="font-light">
              <ul className="list-inside list-disc marker:text-exoticberry mt-5">
            <li>
              Pase doble para asistir al 90 's Pop Tour en la Arena CDMX el 23 de junio
              </li>
              <li>Pase doble para Meet & Greet el día del evento</li>
                <li>Exclusivo Kit Boone’s 90s Pop Tour</li>
            </ul>
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        {/* BASES */}
        <div className="container">
         <h4 className="text-sunpeakpeach uppercase text-xl font-bold">BASES PARA PARTICIPAR:</h4>
         <ol className="list-inside list-decimal marker:text-sunpeakpeach mt-5">
            <li>Seguir a Boone’s en las siguientes redes sociales:</li>
                <ul className="ml-5 list-inside list-disc marker:text-sunpeakpeach mt-1 mb-5">
                    <a href="https://instagram.com/booneslat/"
                target="_blank" className="hover:text-sunpeakpeach"><li>Instagram: @Booneslat</li></a>
                    <a href="https://facebook.com/BoonesLat"
                target="_blank" className="hover:text-sunpeakpeach"><li>Facebook: @Booneslat</li></a>
                    <a href="https://www.tiktok.com/@boonesmx"
                target="_blank" className="hover:text-sunpeakpeach"><li>Tiktok: @Boonesmx</li></a>
                </ul>
            <li>En la cuenta de 90’s Pop Tour se deberá de comentar en el reel de la dinámica lo siguiente:</li>
                <ul className="ml-5 list-inside list-disc marker:text-sunpeakpeach mt-1 mb-5">
                    <li>Cuál es tu canción favorita del 90’s Pop Tour y con qué sabor de Boone’s hace match</li>
                    <li>Etiquetar a la persona con la que irías si ganaras los boletos para el 90’s Pop Tour</li>
                </ul>
                <li>
Los ganadores serán anunciados el miércoles 21 de junio en las cuentas oficiales de Boone’s</li>
            </ol>
        </div>
        {/* Términos y Condiciones */}
        <div className="container mt-20">
         <h6 className="text-zinc-400 capitalize text-xs">términos y condiciones</h6>
         <ul className="list-inside list-disc marker:text-zinc-400 text-zinc-400 font-thin mt-5 text-xs">
            <li>No se incluye transporte ni logística al evento</li>
            <li>El ganador y su acompañante deberán ser mayores de edad</li>
            <li>Los ganadores serán elegidos por un grupo de jueces</li>
            <li>Los ganadores y sus acompañantes deberán ser mayores de edad</li>
            </ul>
        </div>
        {/* INSTRUCCIONES GANADORES */}
        <div className="container mt-20">
         <h4 className="text-sunpeakpeach uppercase text-xl font-bold">INSTRUCCIONES PARA ASISTIR AL EVENTO:</h4>
         <ol className="list-inside list-decimal marker:text-sunpeakpeach mt-5">
            <li>A los ganadores se les solicitará:</li>
                <ul className="ml-5 list-inside list-disc marker:text-sunpeakpeach mt-1 mb-5">
                    <li>Nombre completo como aparece en la INE del ganador y acompañante</li>
                    <li>Fecha de nacimiento</li>
                    <li>Usuarios de:</li>
                        <ul className="ml-5 list-inside list-disc marker:text-sunpeakpeach mt-1 mb-5">
                            <li>Instagram</li>
                            <li>TikTok</li>
                            <li>Facebook</li>
                        </ul>
                    <li>Teléfono</li>
                    <li>Correo Electrónico</li>
                    <li>Acudir el día del evento (viernes 23 de junio del 2023) a la puerta G2 Norte de la Arena CDMX con su acompañante a las 7pm</li>
                    <li>Presentar la identificación oficial del ganador y del acompañante que deberá concordar con el nombre proporcionado.</li>
                </ul>
            <li>Recuerda:</li>
                <ul className="ml-5 list-inside list-disc marker:text-sunpeakpeach mt-1 mb-5">
                    <li>El ganador y su acompañante deben ser mayores de edad</li>
                    <li>No se incluye transporte ni logística al evento</li>
                </ul>
            </ol>
        </div>
        {/* EXTRAS */}
        <div className="container mt-40 mb-20">
        <h4 className="text-sunpeakpeach uppercase text-3xl font-bold text-center">Visita nuestro blog</h4>
          <BlogGridFeat articles={allStrapiArticle.nodes} />
        </div>
        <div className="mt-16">
          <CarruselCompra />
        </div>
        <div className="mt-16">
          <BribeRecetas />
        </div>
        <div className="mt-16">
          <CarruselSabores />
        </div>
      </main>
    </Layout>
  );
}
export default noventasgiveaway;
